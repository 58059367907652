import { Privilege, Role } from "../models/role";

export const rolePrivileges: Record<Role, Privilege[]> = {
    SURVEY_USER: [],
    CUSTOMER: [],
    BASIC_USER: [],
    SUPER_USER: [Privilege.VIEW_DEAL_DATA_HISTORY],
    DEVELOPER: [Privilege.RESTORE_BACKUP, Privilege.COPY_DEAL_DATA, Privilege.VIEW_DEAL_DATA_HISTORY],
    COMPANY: [],
    TIPSTER: [],
};

export const CUSTOMER_ROLES: Role[] = [Role.SURVEY_USER, Role.CUSTOMER, Role.TIPSTER];
