import { ApplicationRoute } from "./shared/types/route";
import App from "./App";
import { Fragment, ReactNode, Suspense, lazy, useContext } from "react";
import { PageLoadingIndicator } from "./ui/components/spinners/PageLoadingIndicator";
import { CookiesProvider } from "react-cookie";
import { Navigate, RouteObject } from "react-router-dom";
import { AuthContext } from "./context/AuthContext";
import { LoginStatus } from "./shared/types/user";
import { createRouteOptions } from "./route-options";
import { config } from "./config";

const pages = [
    {
        Page: lazy(() => import("./ui/pages/signup/SignupPage")),
        path: ApplicationRoute.SIGNUP,
        unprotected: true,
    },
    {
        Page: lazy(() => import("./ui/pages/login/LoginPage")),
        path: ApplicationRoute.LOGIN,
    },
    {
        Page: lazy(() => import("./ui/pages/calculator/CalculatorPage")),
        path: ApplicationRoute.CALCULATOR,
    },
    {
        Page: lazy(() => import("./ui/pages/consultation/ConsultationPage")),
        path: ApplicationRoute.CONSULTATION,
    },
    {
        Page: lazy(() => import("./ui/pages/deal/DealPage")),
        path: ApplicationRoute.DEAL,
    },
    {
        Page: lazy(() => import("./ui/pages/documents/DocumentsPage")),
        path: ApplicationRoute.DOCUMENTS,
    },
    {
        Page: lazy(() => import("./ui/pages/empty/EmptyPage")),
        path: `${ApplicationRoute.DEAL}/:dealId`,
    },
    {
        Page: lazy(() => import("./ui/pages/home/HomePage")),
        path: ApplicationRoute.HOME,
    },
    {
        Page: lazy(() => import("./ui/pages/customerData/CustomerDataPage")),
        path: ApplicationRoute.CUSTOMER_DATA,
    },
    {
        Page: lazy(() => import("./ui/pages/settings/SettingsPage")),
        path: ApplicationRoute.SETTINGS,
    },
    {
        Page: lazy(() => import("./ui/pages/spinner/SpinnerPage")),
        path: ApplicationRoute.DEV,
    },
    {
        Page: lazy(() => import("./ui/pages/submission/SubmissionPage")),
        path: ApplicationRoute.SUBMISSION,
    },
    {
        Page: lazy(() => import("./ui/pages/survey/SurveyPage")),
        path: ApplicationRoute.SURVEY,
        unprotected: true,
        disabled: config.isFinlineProduction,
    },
    {
        Page: lazy(() => import("./ui/pages/additionalInfo/AdditionalInfoPage")),
        path: ApplicationRoute.ADDITIONAL_INFO,
        unprotected: true,
    },
    {
        Page: lazy(() => import("./ui/pages/hub/HubPage")),
        path: ApplicationRoute.HUB,
        unprotected: true,
    },
    {
        Page: lazy(() => import("./ui/pages/scheduler/SchedulerPage")),
        path: ApplicationRoute.SCHEDULER,
    },
    {
        Page: lazy(() => import("./ui/pages/trash/TrashPage")),
        path: ApplicationRoute.TRASH,
    },
    {
        Page: lazy(() => import("./ui/pages/banks/BanksPage")),
        path: ApplicationRoute.CUSTOMER_DATA_BANKS,
    },
    {
        Page: lazy(() => import("./ui/pages/houseBankOffer/HouseBankOfferPage")),
        path: ApplicationRoute.HOUSE_BANK_OFFER,
    },
    {
        Page: lazy(() => import("./ui/pages/companies/CompaniesPage")),
        path: ApplicationRoute.COMPANIES,
    },
    {
        Page: lazy(() => import("./ui/pages/users/UsersPage")),
        path: ApplicationRoute.USERS,
    },
    {
        Page: lazy(() => import("./ui/pages/createDeal/CreateDealPage")),
        path: ApplicationRoute.CREATE_DEAL,
        unprotected: true,
    },
    {
        Page: lazy(() => import("./ui/pages/error/ErrorPage")),
        path: ApplicationRoute.ERROR,
    },
    {
        Page: lazy(() => import("./ui/pages/notFound/NotFoundPage")),
        path: "*",
    },
];

export const routes: RouteObject[] = [
    {
        path: "/",
        element: (
            <CookiesProvider>
                <App />
            </CookiesProvider>
        ),
        children: pages
            .filter((page) => !page.disabled)
            .map(({ path, Page, unprotected }) => ({
                path,
                element: (
                    <Suspense fallback={<PageLoadingIndicator />}>
                        {unprotected ? (
                            <Page />
                        ) : (
                            <ProtectedRoute route={path}>
                                <Page />
                            </ProtectedRoute>
                        )}
                    </Suspense>
                ),
            })),
    },
];

type ProtectedRouteProps = {
    children: ReactNode;
    route: string;
};
function ProtectedRoute({ children, route }: ProtectedRouteProps) {
    const { loginStatus, rolePrivileges, tokenLoginStatus } = useContext(AuthContext);

    const isLoggedOut = loginStatus === LoginStatus.LOGGED_OUT && tokenLoginStatus !== LoginStatus.SUCCESS;

    const routeOptions = createRouteOptions();

    if (route === "*") {
        return <Fragment>{children}</Fragment>;
    }

    if (loginStatus === LoginStatus.LOADING) {
        return <PageLoadingIndicator />;
    }

    if (loginStatus === LoginStatus.ERROR || isLoggedOut) {
        return <Navigate to={ApplicationRoute.LOGIN} />;
    }

    if (loginStatus === LoginStatus.UNAUTHORIZED) {
        return <Navigate to={ApplicationRoute.NOT_FOUND} />;
    }

    if (loginStatus === LoginStatus.SUCCESS && route === ApplicationRoute.LOGIN) {
        return <Navigate to={ApplicationRoute.HOME} />;
    }

    const routeOption = routeOptions.find((option) => option.route === route);

    if (!routeOption) {
        return <Fragment>{children}</Fragment>;
    }

    if (routeOption.isAllowed && !routeOption.isAllowed(rolePrivileges.role)) {
        return <Navigate to={ApplicationRoute.NOT_FOUND} />;
    }

    return <Fragment>{children}</Fragment>;
}
